@import "../../styles/mixins";
@import "../../styles/typography";
@import "../../styles/responsive";

.homepageOverlay {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 1100px;
  z-index: 1100;

  @include only-on(sm) {
    height: 250px;
  }

  @include only-on(md) {
    height: 400px;
  }

  @include only-on(lg) {
    height: 900px;
  }
}

.homepageOverlay__bounds1 {
  position: absolute;
  height: 426px;
  width: 322px;
  z-index: 1200;

  @include only-on(sm) {
    top: -65px;
    right: -24px;
    transform: scale(0.5);
  }

  @include only-on(md) {
    top: 72px;
    right: 10%;
    transform: scale(0.6);
  }

  @include only-on(lg) {
    top: 35px;
    right: 95px;
    transform: scale(0.65);
  }

  @include only-on(xl) {
    top: 75px;
    right: 200px;
    transform: scale(1);
  }
}

.homepageOverlay__butterfly1 {
  position: absolute;
  top: -17px;
  left: 258px;
  right: 150px;
}

.homepageOverlay__butterfly2 {
  position: absolute;
  top: 106px;
  left: 0px;
  right: 50px;
}

.homepageOverlay__butterfly3 {
  position: absolute;
  top: 334px;
  left: 176px;
  right: 250px;
}

.homepageNotesAnimation {
  position: absolute;
  display: block;
  width: 426px;
  height: 426px;
  right: -50px;
}

.homepageOverlay__bounds2 {
  position: absolute;
  top: 650px;
  right: 0;
  height: 426px;
  width: 60%;
  z-index: 1100;

  @include only-on(lg) {
    height: 300px;
    width: 50%;
    top: 450px;
  }
}

.homepageOverlay__phone {
  position: absolute;
  bottom: 0px;
  right: 0px;
  visibility: visible !important;
  img {
    width: 500px;
    height: 686px;

    @include only-on(lg) {
      width: 300px;
      height: 411px;
    }
  }
}

.homepageOverlay__clouds {
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-width: 1000px;
}
