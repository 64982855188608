@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';

.homepageHeroBlock {
  position: relative;
  margin-top: 60px;

  @include only-on(lgUp) {
    margin-top: 95px;
  }
}

.homepageHeroBlock--mobile {
  display: none;
  @include only-on(mdDown) {
    display: block;
  }
}

.homepageHeroBlock__wrapper {
  @include container;

  @include only-on(lgUp) {
    padding-left: 20px;
  }
}

.homepageHeroBlock__primaryContent {
  position: relative;
  background-color: #f9f9f7;
  min-height: 650px;

  @include only-on(lg) {
    min-height: 475px;
  }
}

.homepageHeroBlock__primaryContentBackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
}

.homepageHeroBlock__headerText {
  max-width: 380px;
  padding-top: 50px;
  @include only-on(xl) {
    padding-top: 100px;
  }
  position: relative;
  z-index: 2000;
}

.homepageHeroBlock__header {
  @extend .headerTextLarge;
  line-height: 58px;
  margin-bottom: 30px;
}

.homepageHeroBlock__subheader {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 30px;
}

.homepageHeroBlock__cta {
  @include cta(250px);
  margin-bottom: 30px;
  border: none;
  font-family: var(--omnes-font);
  box-sizing: content-box;

  @include only-on(lg) {
    min-width: 175px;
  }
}

.homepageHeroBlock__cta2 {
  //Hack to allow larger-than-designed button
  //based on content change made by BFF on spanish
  //version of the site
  margin-right: -100px;
}

.homepageHeroBlock__body {
  max-width: 250px;
  font-size: 14px;
  a {
    font-size: 14px;
  }

  @include only-on(lg) {
    max-width: 132px;
  }
}

.homepageHeroBlock__secondaryContent {
  @include container;
}

.homepageHeroBlock__secondaryContent__text {
  margin-top: $block-spacing;
  max-width: 340px;
  position: relative;
  z-index: 1200;
  @include only-on(lgUp) {
    padding-left: 20px;
  }
}

.homepageHeroBlock__secondaryHeader {
  font-size: 42px;
  line-height: 44px;
  font-weight: 600;
  color: $vroom-orange;
  margin-bottom: 30px;
}

.homepageHeroBlock__secondaryBody {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 50px;
}

.homepageHeroBlock__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $vroom-teal;
  cursor: pointer;

  &:hover {
    color: $vroom-teal-hover;
    svg {
      stroke: $vroom-teal-hover;
    }
  }
}

.homepageHeroBlock__secondaryLinkText {
  flex: 1 1 100%;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
}

svg.homepageHeroBlock__secondarylinkIcon {
  flex: initial;
  height: 45px;
  width: 37px;
  margin-right: 20px;
}
