@import "../../styles/mixins";
@import "../../styles/typography";
@import "../../styles/responsive";

@mixin smallContainer {
  margin-left: auto;
  margin-right: auto;
  padding: 0px 20px;
  @include only-on(sm) {
    max-width: 250px;
  }

  @include only-on(md) {
    max-width: 370px;
  }
}

.hhbm {
  text-align: center;
}

.hhbm__primaryBanner {
  position: relative;
  background-color: #f9f9f7;
  min-height: 235px;

  @include only-on(md) {
    min-height: 400px;
  }
}

.hhbm__primaryBannerImage {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
}

.hhbm__headerText {
  @extend .headerTextLarge;
  text-align: left;
  font-size: 30px;
  max-width: 240px;
  padding: 20px;

  position: relative;
  z-index: 2000;

  @include only-on(md) {
    font-size: 45px;
    max-width: 300px;
    padding: 50px;
  }
}

.hhbm__subheader {
  @extend .headerTextSmall;
  @include smallContainer;
  color: $vroom-dark-brown;
  margin-top: 50px;
}

.hhbm__cta {
  @include cta(261px, 70px);
  margin-top: 50px;
  border: none;
  box-sizing: content-box;

  a {
    color: white;
  }
}

.hhbm_body {
  @include smallContainer;
  margin-top: 50px;
}

.hhbm__secondaryHeader {
  @include smallContainer;
  @extend .headerTextSmall;
  font-size: 22px;
  margin-top: 80px;
}

.hhbm__appImage {
  margin-top: 50px;
  position: relative;
}

.hhbm__appImage__phone {
  position: relative;
  max-width: 400px;
  height: auto;
  z-index: 100;
}

.hhbm__appImage__bg {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  z-index: 50;
}

.hhbm__secondaryBody {
  @include smallContainer;
  margin-top: 50px;
}

.hhbm__secondaryLinkIcon {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepageHeroBlockMobile__secondaryLinkText {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
}
